export const SHIPMENT_SUMMARY_FETCH_SHIPMENTS_SUCCESS =
  'SHIPMENT_SUMMARY_FETCH_SHIPMENTS_SUCCESS'
export const SHIPMENT_SUMMARY_FETCH_SHIPMENTS_FAILURE =
  'SHIPMENT_SUMMARY_FETCH_SHIPMENTS_FAILURE'
export const SHIPMENT_SUMMARY_FETCH_SHIPMENTS_PENDING =
  'SHIPMENT_SUMMARY_FETCH_SHIPMENTS_PENDING'
export const SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_FAILURE =
  'SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_FAILURE'
export const SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_SUCCESS =
  'SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_SUCCESS'
export const SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_PENDING =
  'SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_PENDING'
export const SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_SUCCESS =
  'SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_SUCCESS'
export const SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_FAILURE =
  'SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_FAILURE'
export const SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_PENDING =
  'SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_PENDING'
export const SHIPMENT_SUMMARY_UPDATE_SHIPMENT_TRACKING =
  'SHIPMENT_SUMMARY_UPDATE_SHIPMENT_TRACKING'
export const SHIPMENT_SUMMARY_UPDATE_SHIPMENT_CARRIER =
  'SHIPMENT_SUMMARY_UPDATE_SHIPMENT_CARRIER'
export const SHIPMENT_SUMMARY_RESET_SHIPMENT_TRACKING_EDITS =
  'SHIPMENT_SUMMARY_RESET_SHIPMENT_TRACKING_EDITS'
export const SHIPMENT_SUMMARY_SET_SHIPMENTS_WITH_INCOMPLETE_TRACKING =
  'SHIPMENT_SUMMARY_SET_SHIPMENTS_WITH_INCOMPLETE_TRACKING'
export const SHIPMENT_SUMMARY_REMOVE_SHIPMENT_WITH_INCOMPLETE_TRACKING =
  'SHIPMENT_SUMMARY_REMOVE_SHIPMENT_WITH_INCOMPLETE_TRACKING'
export const SHIPMENT_SUMMARY_SET_PAGE = 'SHIPMENT_SUMMARY_SET_PAGE'
export const SHIPMENT_SUMMARY_SET_ROWS_PER_PAGE =
  'SHIPMENT_SUMMARY_SET_ROWS_PER_PAGE'
export const SHIPMENT_SUMMARY_SET_TOTAL_ROWS_COUNT =
  'SHIPMENT_SUMMARY_SET_TOTAL_ROWS_COUNT'
export const SHOW_SHIPMENT_SUMMARY_POPUP = 'SHOW_SHIPMENT_SUMMARY_POPUP'
export const CLOSE_SHIPMENT_SUMMARY_POPUP = 'CLOSE_SHIPMENT_SUMMARY_POPUP'
export const SHIPMENT_SUMMARY_SET_CHECKBOX = 'SHIPMENT_SUMMARY_SET_CHECKBOX'
export const SHIPMENT_SUMMARY_SET_CHECKBOX_HEADER =
  'SHIPMENT_SUMMARY_SET_CHECKBOX_HEADER'
export const SET_TAB_VALUE= 'SET_TAB_VALUE'
