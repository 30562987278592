import * as types from './actionType'
import * as shipmentAPI from '../../services/shipmentService'
import * as orderAPI from '../../services/ordersService'
import * as itemAPI from '../../services/itemService'
import * as shipmentMapper from '../../mappers/shipmentMapper'
import * as orderMapper from '../../mappers/OrderMapper'
import * as itemMapper from '../../mappers/itemMapper'
import { showNotification } from '../notification/actionCreator'
import { SampleStatus } from '../../enums/sampleStatus'

export const fetchShipments = () => {
  return (dispatch) => {
    dispatch(fetchShipmentsPending(true))
    getShipmentsForVendor()
      .then((allShipments) => {
        dispatch(fetchShipmentsSuccess(allShipments?.shipmentsWithTracking))
        dispatch(setShipmentsWithIncompleteTracking(allShipments?.allshipments))
      })
      .catch(() => {
        dispatch(fetchShipmentsFailure())
        dispatch(
          showNotification(
            true,
            'Something went wrong fetching shipments. Please try again.'
          )
        )
      })
      .finally(() => {
        dispatch(fetchShipmentsPending(false))
      })
  }
}

const getShipmentsForVendor = () => {
  return shipmentAPI.fetchShipmentsByVendor().then((shipmentResult) => {
    return shipmentMapper.shipmentsFilteredResponseToShipments(shipmentResult)
  })
}

export const setShipmentsWithIncompleteTracking = (shipmentIdArray) => {
  return {
    type: types.SHIPMENT_SUMMARY_SET_SHIPMENTS_WITH_INCOMPLETE_TRACKING,
    payload: { data: shipmentIdArray },
  }
}

export const removeShipmentWithIncompleteTracking = (shipmentId) => {
  return {
    type: types.SHIPMENT_SUMMARY_REMOVE_SHIPMENT_WITH_INCOMPLETE_TRACKING,
    payload: { data: shipmentId },
  }
}

export const fetchShipmentsSuccess = (data) => {
  return {
    type: types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_SUCCESS,
    payload: { data: data },
  }
}

export const fetchShipmentsPending = (pending = false) => {
  return {
    type: types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_PENDING,
    payload: { pending: pending },
  }
}

export const fetchShipmentsFailure = () => {
  return {
    type: types.SHIPMENT_SUMMARY_FETCH_SHIPMENTS_FAILURE,
  }
}

export const getSamplesByVendor = (page, rowsPerPage) => {
  return (dispatch) => {
    dispatch(getSamplesByVendorPending(true))
    getVendorSamples(page, rowsPerPage)
      .then((orders) => {
        if (orders) {
          dispatch(getSamplesByVendorPending(false))
          dispatch(getSamplesByVendorSuccess(orders.samples.flat()))
          dispatch(setTotalRowsCount(orders.totalRowsCount))
        } else {
          dispatch(getSamplesByVendorFailure())
          dispatch(
            showNotification(
              true,
              'Something went wrong getting shipped samples. Please try again later.'
            )
          )
        }
      })
      .catch(() => {
        dispatch(getSamplesByVendorFailure())
        dispatch(
          showNotification(
            true,
            'Something went wrong getting shipped samples. Please try again later.'
          )
        )
      })
      .finally(() => {
        dispatch(getSamplesByVendorPending(false))
      })
  }
}

function getVendorSamples(page, rowsPerPage) {
  return orderAPI
    .fetchSamplesByVendor(SampleStatus.SHIPPED, page, rowsPerPage)
    .then((results) => {
      return {
        samples: orderMapper.samplesResponseToSamples(results),
        totalRowsCount: results.data.total_count,
      }
    })
    .then((orders = {}) => {
      const { samples: ordersSamples = [], totalRowsCount = Number(0) } = orders
      return ordersSamples.length > 0
        ? itemAPI
            .getItemsGraphQL(ordersSamples.map((it) => it.item_data?.tcin))
            .then((itemResult) => itemMapper.itemsResponseToItems(itemResult))
            .then(
              (items) =>
                new Map(items.map((item) => [item.tcin.toString(), item]))
            )
            .then((itemMap) => {
              return {
                samples: ordersSamples.map((order = {}) => {
                  const { tcin: orderTcin = '' } = order
                  return Object.assign(
                    {},
                    order,
                    itemMap.get(orderTcin.toString())
                  )
                }),
                totalRowsCount: totalRowsCount,
              }
            })
        : { samples: ordersSamples, totalRowsCount: totalRowsCount }
    })
    .catch(() => {})
}
export const setTabvalue = (data='') => {
  return {
    type: types.SET_TAB_VALUE,
    payload: {
      tabValue: data,
    },
  }
}

export const getSamplesByVendorSuccess = (data) => {
  return {
    type: types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_SUCCESS,
    payload: {
      sampleData: data,
    },
  }
}

export const getSamplesByVendorPending = (pending = false) => {
  return {
    type: types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_PENDING,
    payload: { pending: pending },
  }
}

export const getSamplesByVendorFailure = () => {
  return {
    type: types.SHIPMENT_SUMMARY_GET_SAMPLES_BY_VENDOR_FAILURE,
  }
}

export const updateShipmentDetails = (shipmentDetail) => {
  return (dispatch) => {
    dispatch(updateShipmentDetailsRequestPending(true))
    shipmentAPI
      .updateShipmentDetails(
        shipmentMapper.shipmentToUpdateRequest(shipmentDetail)
      )
      .then((shipmentUpdateResult) =>
        shipmentMapper.shipmentResponseToShipment(shipmentUpdateResult.data)
      )
      .then((result) => {
        dispatch(updateShipmentDetailsRequestSuccess(result))
      })
      .catch(() => {
        dispatch(updateShipmentDetailsRequestFailure())
        dispatch(
          showNotification(
            true,
            'Something went wrong updating the shipment, please try again.'
          )
        )
      })
      .then(dispatch(fetchShipments()))
      .finally(() => {
        dispatch(updateShipmentDetailsRequestPending(false))
      })
  }
}

export const updateShipmentDetailsRequestSuccess = (shipment) => {
  return {
    type: types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_SUCCESS,
    payload: { data: shipment },
  }
}

export const updateShipmentDetailsRequestPending = (pending = false) => {
  return {
    type: types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_PENDING,
    payload: { pending: pending },
  }
}

export const updateShipmentDetailsRequestFailure = () => {
  return {
    type: types.SHIPMENT_SUMMARY_UPDATE_SHIPMENT_DETAILS_REQUEST_FAILURE,
  }
}

export function setPage(page) {
  return {
    type: types.SHIPMENT_SUMMARY_SET_PAGE,
    payload: { page: page },
  }
}

export function setRowsPerPage(rowsPerPage) {
  return {
    type: types.SHIPMENT_SUMMARY_SET_ROWS_PER_PAGE,
    payload: { rowsPerPage: rowsPerPage },
  }
}

export function setTotalRowsCount(totalRowsCount = 0) {
  return {
    type: types.SHIPMENT_SUMMARY_SET_TOTAL_ROWS_COUNT,
    payload: { totalRowsCount: totalRowsCount },
  }
}

export function showPopup(item) {
  return {
    type: types.SHOW_SHIPMENT_SUMMARY_POPUP,
    payload: { item: item },
  }
}

export function closePopup() {
  return {
    type: types.CLOSE_SHIPMENT_SUMMARY_POPUP,
  }
}

export function setCheckBox(selected) {
  return {
    type: types.SHIPMENT_SUMMARY_SET_CHECKBOX,
    payload: { selected: selected },
  }
}

export function setCheckBoxHeaderChange(selected = []) {
  return {
    type: types.SHIPMENT_SUMMARY_SET_CHECKBOX_HEADER,
    payload: { selected: selected },
  }
}
