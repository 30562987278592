import { PROPS, SCENIC } from 'enums/Tabs'
import apiConfig from '../../apiConfig'
import { isEmpty, get, isArray } from 'lodash'
import moment from 'moment'
/**
 * popupFiltersUsedBy: List of pagenames
 * Used to update the selected filters by pagename
 * for auto-populating filter values based on pagename
 * */
export const popupFiltersUsedBy = [
  'AutoOrder',
  'Projects',
  'RequestedSamples',
  'OrderedSamples',
  'Props',
]
export const regexExpressions = {
  DPCI: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
  'PID and COP/VAP': /^[\s\S*]{1,30}$/,
  'Pegasus ID': /^([a-zA-Z]{2})?[0-9]{1,8}$/,
  'Filemaker ID': /^([a-zA-Z]{0,3})?[0-9]{1,8}$/,
  TCIN: /^[0-9]{8,10}$/,
  'Location ID': /^[0-9]{1,9}$/,
  'Vendor ID': /^[0-9]{7}$/,
  'Vendor Name': /^[\s\S*]{1,30}$/,
  'Project/Collection Creator': /^[0-9a-zA-Z\s*.*]{1,40}$/,
  'Barcode Number': /^[0-9]{12}$/,
  'Manufacturer Style': /^.*$/,
  'Order Number': /^[0-9]{10}$/,
}
const styles = {
  labelStyle: {
    fontSize: 10,
  },
  labelRoot: {
    margin: 4,
  },
}
const generateVendorStatusLabel = (label = '', subLabel = '') => {
  return (
    <div style={styles.labelRoot}>
      <div>{label}</div>
      <div style={styles.labelStyle}>{subLabel}</div>
    </div>
  )
}
export const autocompleteSelectData = {
  Status: [
    { value: 'SAMPLE_INITIATED', label: 'Order Initiated' },
    { value: 'SAMPLE_ORDERED', label: 'Ordered' },
    { value: 'SAMPLE_SHIPPED', label: 'Shipped from Vendor' },
    { value: 'SAMPLE_RECEIVED', label: 'Received' },
    { value: 'SAMPLE_HOLD', label: 'Held for Photography' },
    { value: 'SAMPLE_SHOOT_SHIPPED', label: 'Shipped for Photography' },
    { value: 'SAMPLE_ARCHIVED', label: 'Archived' },
  ],
  'Project Channel': [],
  'Shipment Channel': [],
  'Vendor Sample Status': [
    {
      value: 'SAMPLES_ORDERED_RECENTLY',
      label: generateVendorStatusLabel('New', 'Added in the last 7 days'),
      quickFilterLabel: 'New',
    },
    {
      value: 'SAMPLES_COMING_DUE',
      label: generateVendorStatusLabel('Due Soon', 'Due in the next 7 days'),
      quickFilterLabel: 'Due Soon',
    },
    {
      value: 'SAMPLES_PAST',
      label: generateVendorStatusLabel('Late', 'Past Due Date'),
      quickFilterLabel: 'Late',
    },
  ],
  'Ordered By Role': [
    { value: 'IDS', label: 'IDS' },
    { value: 'SMS', label: 'SMS' },
    { value: 'SAMPLE_MANAGER', label: 'Sample Manager' },
  ],
}

export const nameBasedListInputs = ['Project/Collection Creator']

export const multiSelect = [
  'Brand',
  'Project Channel',
  'Location',
  'Ship To Location',
  'Shipment Ship From',
  'Shipment Ship To',
  'Status',
  'Vendor Sample Status',
  'Ordered By',
  'Ordered By Role',
  'Shipment Channel',
  'Shipment Creator',
  'Props Specialist',
  'Props Color',
  'Props Material',
  'Scenic Source',
  'Scenic Created By',
  'Scenic Room',
]
export const projectsCollectionFilter = [
  'Projects-Current',
  'Projects-Completed',
  'Collections-Current',
  'Collections-Completed',
  'Collections-Cancelled',
  'Project/Collection Name',
]
export const projectsCollectionFilterNames = [
  'Projects-Current',
  'Projects-Completed',
]
export const deptClassInputs = [
  'Dept-Class',
  'Props Category- Sub Category',
  'Scenic Category - Sub Category',
]
export const locationLevelsInputs = ['Location-WinterStreet']
export const listInputs = [
  'DPCI',
  'PID and COP/VAP',
  'Pegasus ID',
  'Prop ID',
  'TCIN',
  'Vendor ID',
  'Project/Collection Creator',
  'Location ID',
  'Barcode Number',
  'Manufacturer Style',
  'Order Number',
  'Filemaker ID',
  'Scenic Filemaker ID',
]
export const dateInputs = [
  'Shoot Date',
  'Disposition Date',
  'Disposition Override Date',
  'Vendor Ship Date',
  'Received Date',
  'Ordered Date',
  'Store Clearance Date',
  'Digital Clearance Date',
  'Launch Date',
  'Shipment Date',
  'Sample Due Date',
  'Last Shipped Date',
  'Props Added Date',
  // 'Scenic Shoot Date',
  'Scenic Created Date',
  // 'Scenic Ship Date',
]
export const booleanInputs = ['Props Bundled', 'Scenic Bundled']

export const DBNamesMap = {
  Brand: 'brand',
  'Project Channel': 'channel',
  'Dept-Class': 'dept_class',
  'Scenic-Category': 'scenic_category_ids',
  'Scenic-Sub Category': 'scenic_sub_category_ids',
  Category: 'category_ids',
  'Sub Category': 'sub_category_ids',
  'Location-WinterStreet': 'location_id',
  DPCI: 'dpci',
  Location: 'location',
  'Location ID': 'location_id',
  'Ship To Location': 'ship_to_location',
  'Shipment Ship From': 'shipment_ship_from',
  'Shipment Ship To': 'shipment_ship_to',
  'Ordered By': 'ordered_by',
  'Props Specialist': 'props_specialist_user_ids',
  'Props Material': 'material_ids',
  'Project/Collection Creator': 'project_creator',
  'PID and COP/VAP': 'marketing_pid',
  'Project/Collection Name': 'project_name',
  'Pegasus ID': 'sample_id',
  'Scenic Created Date': 'scenic_created_',
  'Filemaker ID': 'prop_ids',
  'Scenic Filemaker ID': 'scenic_filemaker_ids',
  'Prop ID': 'prop_ids',
  Status: 'status',
  TCIN: 'tcin',
  'Shoot Date': 'shoot_',
  'Disposition Date': 'disposition_',
  'Disposition Override Date': 'disposition_override_',
  'Vendor ID': 'vendor_id',
  'Barcode Number': 'upc',
  'Manufacturer Style': 'manufacturer_style',
  'Vendor Ship Date': 'vendor_ship_',
  'Last Shipped Date': 'last_shipped_',
  'Received Date': 'received_',
  'Ordered Date': 'ordered_',
  'Props Added Date': 'ordered_',
  'Store Clearance Date': 'store_clearance_',
  'Digital Clearance Date': 'digital_clearance_',
  'Launch Date': 'current_launch_',
  'Vendor Sample Status': 'vendor_sample_status',
  'Ordered By Role': 'ordered_by_role',
  'Shipment Creator': 'shipment_creator',
  'Shipment Channel': 'shipment_channel',
  'Shipment Date': 'shipment_',
  'Order Number': 'order_num',
  'Sample Due Date': 'sample_due_',
  Project_id: 'Collections-Current',
  'Props Color': 'color_ids',
  'Props Bundled': 'is_bundle',
  'Scenic Bundled': 'scenic_is_bundle',
  'Scenic ID': 'scenic_filemaker_ids',
  'Scenic Source': 'scenic_source_ids',
  'Scenic Created By': 'scenic_specialist_user_ids',
  'Scenic Room': 'scenic_room_ids',
}
export const DBToFilterNamesMap = {
  brand: 'Brand',
  channel: 'Project Channel',
  dept_class: 'Dept-Class',
  roy_location_id: 'Location ID',
  dpci: 'DPCI',
  location: 'Location',
  location_id: 'Location ID',
  ship_to_location: 'Ship To Location',
  shipment_ship_from: 'Shipment Ship From',
  shipment_ship_to: 'Shipment Ship To',
  ordered_by: 'Ordered By',
  project_creator: 'Project/Collection Creator',
  marketing_pid: 'PID and COP/VAP',
  project_name: 'Project/Collection Name',
  sample_id: 'Pegasus ID',
  status: 'Status',
  tcin: 'TCIN',
  shoot_start_date: 'Shoot Date',
  shoot_end_date: 'Shoot Date',
  disposition_start_date: 'Disposition Date',
  disposition_end_date: 'Disposition Date',
  vendor_id: 'Vendor ID',
  upc: 'Barcode Number',
  manufacturer_style: 'Manufacturer Style',
  vendor_ship_start_date: 'Vendor Ship Date',
  vendor_ship_end_date: 'Vendor Ship Date',
  received_start_date: 'Received Date',
  received_end_date: 'Received Date',
  ordered_start_date: 'Ordered Date',
  ordered_end_date: 'Ordered Date',
  store_clearance_start_date: 'Store Clearance Date',
  store_clearance_end_date: 'Store Clearance Date',
  digital_clearance_start_date: 'Digital Clearance Date',
  digital_clearance_end_date: 'Digital Clearance Date',
  current_launch_end_date: 'Launch Date',
  current_launch_start_date: 'Launch Date',
  vendor_sample_status: 'Vendor Sample Status',
  ordered_by_role: 'Ordered By Role',
  shipment_creator: 'Shipment Creator',
  shipment_channel: 'Shipment Channel',
  shipment_start_date: 'Shipment Date',
  shipment_end_date: 'Shipment Date',
  order_num: 'Order Number',
  sample_due_start_date: 'Sample Due Date',
  sample_due_end_date: 'Sample Due Date',
  disposition_override_start_date: 'Disposition Override Date',
  disposition_override_end_date: 'Disposition Override Date',
  project_id: 'Collections-Current',
  'Collections-Current': 'Collections-Current',
  'Collections-Cancelled': 'Collections-Cancelled',
  'Projects-Current': 'Projects-Current',
  'Projects-Completed': 'Projects-Completed',
  'Collections-Completed': 'Collections-Completed',
  color_ids: 'Props Color',
  last_shipped_start_date: 'Last Shipped Date',
  last_shipped_end_date: 'Last Shipped Date',
  is_bundle: 'Props Bundled',
  scenic_is_bundle: 'Scenic Bundled',
  material_ids: 'Props Material',
  props_specialist_user_ids: 'Props Specialist',
  categoryChipArray: 'Props Category- Sub Category',
  categorySubCategoryArray: 'Props Category- Sub Category',
  scenicCategoryChipArray: 'Scenic Category - Sub Category',
  scenicCategorySubCategoryArray: 'Scenic Category - Sub Category',
  prop_ids: 'Filemaker ID',
  Props_Added_end_date: 'Props Added Date',
  Props_Added_start_date: 'Props Added Date',
  scenic_category_ids: 'Scenic-Category',
  scenic_sub_category_ids: 'Scenic-Sub Category',
  scenic_filemaker_ids: 'Scenic Filemaker ID',
  scenic_source_ids: 'Scenic Source',
  scenic_specialist_user_ids: 'Scenic Created By',
  scenic_created_end_date: 'Scenic Created Date',
  scenic_created_start_date: 'Scenic Created Date',
  scenic_room_ids: 'Scenic Room',
}

export const MYORDER_VENDOR_SELECTABLE_FILTERS = [
  'Brand',
  'Project Channel',
  'Dept-Class',
  'DPCI',
  'Location',
  'Location ID',
  'Project/Collection Creator',
  'PID and COP/VAP',
  'Project/Collection Name',
  'Pegasus ID',
  'Shoot Date',
  'Disposition Date',
  'Disposition Override Date',
  'TCIN',
  'Vendor ID',
  'Manufacturer Style',
  'Barcode Number',
  'Status',
  'Vendor Ship Date',
  'Received Date',
  'Ordered Date',
  'Store Clearance Date',
  'Digital Clearance Date',
  'Launch Date',
  'Shipment Channel',
]
export const MYORDER_SELECTABLE_FILTERS = [
  'Brand',
  'Project Channel',
  'Dept-Class',
  'DPCI',
  'Location',
  'Location ID',
  'Project/Collection Creator',
  'PID and COP/VAP',
  'Project/Collection Name',
  'Pegasus ID',
  'Shoot Date',
  'Disposition Date',
  'Disposition Override Date',
  'TCIN',
  'Vendor ID',
  'Manufacturer Style',
  'Barcode Number',
  'Ship To Location',
  'Status',
  'Vendor Ship Date',
  'Received Date',
  'Ordered Date',
  'Store Clearance Date',
  'Digital Clearance Date',
  'Launch Date',
  'Shipment Channel',
  'Sample Due Date',
  'Scenic Source',
]

export const ORDERSUMMARY_SELECTABLE_FILTERS = [
  'DPCI',
  'Pegasus ID',
  'TCIN',
  'Dept-Class',
  'Vendor Sample Status',
  'Ship To Location',
  'Launch Date',
  'Brand',
  'Manufacturer Style',
  'Order Number',
]

export const SEARCH_SCENIC_FILTERS = [
  'Scenic Created Date',
  'Scenic Source',
  'Scenic Category - Sub Category',
  'Scenic Created By',
  'Pegasus ID',
  'Scenic Filemaker ID',
  'Shoot Date',
  'Last Shipped Date',
  'Sample Current Location',
  'Status',
  'Scenic Bundled',
  // 'Scenic Ship Date',
  // 'Scenic Shoot Date',
]

export const SEARCH_VENDOR_SELECTABLE_FILTERS = [
  '',
  'Brand',
  'Project Channel',
  'Dept-Class',
  'DPCI',
  'Location',
  'Location ID',
  'Ordered By',
  'Project/Collection Creator',
  'PID and COP/VAP',
  'Project/Collection Name',
  'Pegasus ID',
  'Shoot Date',
  'Disposition Date',
  'Disposition Override Date',
  'Status',
  'TCIN',
  'Vendor ID',
  'Manufacturer Style',
  'Barcode Number',
  'Vendor Ship Date',
  'Received Date',
  'Ordered Date',
  'Store Clearance Date',
  'Digital Clearance Date',
  'Launch Date',
]

export const SEARCH_SELECTABLE_FILTERS = [
  '',
  'Brand',
  'Project Channel',
  'Dept-Class',
  'Location-WinterStreet',
  'DPCI',
  'Location',
  'Location ID',
  'Ordered By',
  'Project/Collection Creator',
  'PID and COP/VAP',
  'Pegasus ID',
  'Shoot Date',
  'Disposition Date',
  'Disposition Override Date',
  'Status',
  'TCIN',
  'Vendor ID',
  'Manufacturer Style',
  'Barcode Number',
  'Ship To Location',
  'Shipment Ship From',
  'Shipment Ship To',
  'Vendor Ship Date',
  'Received Date',
  'Ordered Date',
  'Store Clearance Date',
  'Digital Clearance Date',
  'Launch Date',
  'Ordered By Role',
  'Projects-Current',
  'Projects-Completed',
  'Collections-Current',
  'Collections-Completed',
  'Collections-Cancelled',
  'Shipment Creator',
  'Shipment Channel',
  'Shipment Date',
  'Sample Due Date',
  'Props Category- Sub Category',
  'Props Material',
  'Props Color',
  'Props Specialist',
  'Filemaker ID',
  'Props Bundled',
  'Last Shipped Date',
  'Props Added Date',
  'Scenic Room',
]

export const SEARCH_ARCHIVED_SELECTABLE_FILTERS = [
  '',
  'DPCI',
  'Pegasus ID',
  'TCIN',
]

export const SEARCH_ARCHIVED_VENDOR_SELECTABLE_FILTERS = [
  '',
  'DPCI',
  'Pegasus ID',
  'TCIN',
]

export const SEARCH_EXCLUDABLE_FILTERS = [
  '',
  'Brand',
  'Dept-Class',
  'Location',
  'Projects-Current',
  'Shoot Date',
  'Status',
  'Location-WinterStreet',
  'Disposition Override Date',
  'Launch Date',
  'Last Shipped Date',
  'Props Added Date',
  'Props Bundled',
  'Props Category- Sub Category',
  'Props Color',
  'Props Material',
  'Props Specialist',
]
export const paramsFromFilters = (
  filterTypeObject = {},
  page = 1,
  rowsPerPage = 10,
  userProfilename = '',
  additionalParams = {},
  orderBy = '',
  order = '',
  searchQuery = '',
  saveFilters = false,
  currentTabValue = PROPS
) => {
  const newFiltersObject = !isEmpty(filterTypeObject)
    ? Object.keys(filterTypeObject)
    : []
  let params = new URLSearchParams()
  params.append('key', apiConfig.apiKey)
  if (page) params.append('page', page)
  if (rowsPerPage) params.append('per_page', rowsPerPage)
  if (!isEmpty(orderBy)) params.append('sort_by', orderBy)
  if (!isEmpty(order)) params.append('sort_order', order)
  if (!isEmpty(searchQuery)) params.append('q', searchQuery)
  let finalObj = {
    include_filters: {},
    exclude_filters: {},
  }

  newFiltersObject.forEach((filterType) => {
    let arrayParams = []
    const filtersObject = filterTypeObject[filterType] || []
    const filters = !isEmpty(filtersObject) ? Object.keys(filtersObject) : []
    let finalObjByType = {}
    for (let index in filters) {
      if (
        multiSelect.includes(filters[index]) &&
        filtersObject[filters[index]].chipArray
      ) {
        const appendValue = filtersObject[filters[index]].chipArray.map(
          (a) => a.value
        )
        if (appendValue.length > 0) {
          finalObjByType = Object.assign({}, finalObjByType, {
            [DBNamesMap[filters[index]]]: filtersObject[
              filters[index]
            ].chipArray.map((a) => a.value),
          })
        }
      } else if (
        projectsCollectionFilter.indexOf(filters[index]) !== -1 &&
        filtersObject[filters[index]].chipArray
      ) {
        if (saveFilters) {
          let newCollectionObject = {}
          newCollectionObject['filter'] = filters[index]
          newCollectionObject['value'] = filtersObject[
            filters[index]
          ].chipArray.map((a) => a.value)
          arrayParams.push(newCollectionObject)
        } else {
          arrayParams.push(
            filtersObject[filters[index]].chipArray.map((a) => a.value)
          )
        }
      } else if (
        listInputs.includes(filters[index]) &&
        filtersObject[filters[index]].chipArray
      ) {
        if (['Pegasus ID'].indexOf(filters[index]) !== -1) {
          let idsWithoutPrefix = []
          for (let index2 in filtersObject[filters[index]].chipArray) {
            if (
              /^[a-zA-Z]{2}$/.test(
                filtersObject[filters[index]].chipArray[index2]?.substring(0, 2)
              )
            ) {
              idsWithoutPrefix.push(
                filtersObject[filters[index]].chipArray[index2]?.substring(2)
              )
            } else {
              idsWithoutPrefix.push(
                filtersObject[filters[index]].chipArray[index2]
              )
            }
          }
          finalObjByType = Object.assign({}, finalObjByType, {
            [DBNamesMap[filters[index]]]: idsWithoutPrefix,
          })
        } else {
          finalObjByType = Object.assign({}, finalObjByType, {
            [DBNamesMap[filters[index]]]:
              filtersObject[filters[index]].chipArray,
          })
        }
      } else if (dateInputs.includes(filters[index])) {
        if (saveFilters && filters[index] === 'Props Added Date') {
          if (filtersObject[filters[index]].startDate) {
            finalObjByType = Object.assign({}, finalObjByType, {
              Props_Added_start_date: moment(
                filtersObject[filters[index]].startDate
              ).format('YYYY-MM-DD'),
            })
          }
          if (filtersObject[filters[index]].endDate) {
            finalObjByType = Object.assign({}, finalObjByType, {
              Props_Added_end_date: moment(
                filtersObject[filters[index]].endDate
              ).format('YYYY-MM-DD'),
            })
          }
        } else {
          if (filtersObject[filters[index]].startDate) {
            finalObjByType = Object.assign({}, finalObjByType, {
              [DBNamesMap[filters[index]] + 'start_date']: moment(
                filtersObject[filters[index]].startDate
              ).format('YYYY-MM-DD'),
            })
          }
          if (filtersObject[filters[index]].endDate) {
            finalObjByType = Object.assign({}, finalObjByType, {
              [DBNamesMap[filters[index]] + 'end_date']: moment(
                filtersObject[filters[index]].endDate
              ).format('YYYY-MM-DD'),
            })
          }
        }
      } else if (
        filtersObject[filters[index]].deptClassChipArray ||
        filtersObject[filters[index]].deptChipArray
      ) {
        let combinedArray = []
        if (filtersObject[filters[index]].deptClassChipArray) {
          combinedArray.push(
            ...filtersObject[filters[index]].deptClassChipArray
          )
        }
        if (filtersObject[filters[index]].deptChipArray) {
          combinedArray.push(...filtersObject[filters[index]].deptChipArray)
        }
        finalObjByType = Object.assign({}, finalObjByType, {
          [DBNamesMap[filters[index]]]: combinedArray,
        })
      } else if (
        !isEmpty(filtersObject[filters[index]].categorySubCategoryArray) ||
        !isEmpty(filtersObject[filters[index]].categoryChipArray) ||
        !isEmpty(filtersObject[filters[index]].scenicCategorySubCategoryArray)
      ) {
        let combinedCtegoryArray = []
        let combinedSubCategoryArray = []
        if (saveFilters) {
          if (currentTabValue === SCENIC) {
            if (filtersObject[filters[index]].categorySubCategoryArray) {
              finalObjByType = Object.assign({}, finalObjByType, {
                scenicCategorySubCategoryArray:
                  filtersObject[filters[index]].categorySubCategoryArray,
              })
            } else if (filtersObject[filters[index]].categoryChipArray) {
              finalObjByType = Object.assign({}, finalObjByType, {
                scenicCategoryChipArray:
                  filtersObject[filters[index]].categoryChipArray,
              })
            }
          } else {
            if (filtersObject[filters[index]].categorySubCategoryArray) {
              finalObjByType = Object.assign({}, finalObjByType, {
                categorySubCategoryArray:
                  filtersObject[filters[index]].categorySubCategoryArray,
              })
            } else if (filtersObject[filters[index]].categoryChipArray) {
              finalObjByType = Object.assign({}, finalObjByType, {
                categoryChipArray:
                  filtersObject[filters[index]].categoryChipArray,
              })
            }
          }
        } else {
          if (
            filtersObject[filters[index]].categorySubCategoryArray ||
            filtersObject[filters[index]].scenicCategorySubCategoryArray
          ) {
            let localArray =
              filtersObject[filters[index]]?.categorySubCategoryArray ||
              filtersObject[filters[index]].scenicCategorySubCategoryArray
            combinedCtegoryArray.push(
              ...localArray.map((a) => {
                return a?.split('-')[0]
              })
            )
            combinedSubCategoryArray.push(
              ...localArray.map((a) => {
                return a?.split('-')[1]
              })
            )
          }
          if (filtersObject[filters[index]].categoryChipArray) {
            combinedCtegoryArray.push(
              ...filtersObject[filters[index]].categoryChipArray
            )
          } else if (filtersObject[filters[index]].scenicCategoryArray) {
            combinedCtegoryArray.push(
              ...filtersObject[filters[index]].scenicCategoryArraypp
            )
          }

          finalObjByType =
            currentTabValue !== SCENIC
              ? Object.assign({}, finalObjByType, {
                  [DBNamesMap['Category']]: combinedCtegoryArray,
                  [DBNamesMap['Sub Category']]: combinedSubCategoryArray,
                })
              : Object.assign({}, finalObjByType, {
                  [DBNamesMap['Scenic-Category']]: combinedCtegoryArray,
                  [DBNamesMap['Scenic-Sub Category']]: combinedSubCategoryArray,
                })
          isEmpty(filtersObject[filters[index]].categorySubCategoryArray) &&
            delete finalObjByType['sub_category_ids']
        }
      } else if (booleanInputs.includes(filters[index])) {
        if (saveFilters) {
          finalObjByType = Object.assign({}, finalObjByType, {
            [DBNamesMap[filters[index]]]: [filtersObject[filters[index]]],
          })
        } else {
          finalObjByType = Object.assign({}, finalObjByType, {
            [DBNamesMap[filters[index]]]:
              filtersObject[filters[index]] === 'bundled' ? true : false,
          })
        }
      }
    }

    if (Object.keys(filtersObject).indexOf('Location-WinterStreet') !== -1) {
      const locationArray = []
      locationArray.push(
        filtersObject['Location-WinterStreet']['textFieldValue']
      )
      const locationStringList = locationArray[0]?.split(',')
      finalObjByType = Object.assign({}, finalObjByType, {
        roy_location_id: locationStringList,
      })
    }

    if (
      !isEmpty(get(finalObjByType, 'project_name', '')) &&
      filters.indexOf('Project/Collection Name') === -1
    ) {
      delete finalObjByType['project_name']
    }

    if (!isEmpty(arrayParams)) {
      if (saveFilters) {
        arrayParams.map((arrayParamsInstance) => {
          const { value = [], filter: filterName } = arrayParamsInstance
          finalObjByType = Object.assign({}, finalObjByType, {
            [filterName]: [].concat.apply([], value),
          })
          return {}
        })
      } else {
        let modifiedArrayParams = [].concat.apply([], arrayParams)
        finalObjByType = Object.assign({}, finalObjByType, {
          project_id: [].concat.apply([], modifiedArrayParams),
        })
      }
    }
    if (saveFilters && isEmpty(finalObjByType)) {
      for (let index in filters) {
        finalObjByType = Object.assign({}, finalObjByType, {
          [DBNamesMap[filters[index]]]: [],
        })
      }
    }
    finalObj[filterType] = finalObjByType
  })

  if (!isEmpty(additionalParams['ordered_by'])) {
    const { include_filters: finalIncludeObj = {} } = finalObj
    const finalIncludeObjUpd = Object.assign({}, finalIncludeObj, {
      ordered_by: additionalParams['ordered_by'],
    })
    finalObj = Object.assign({}, finalObj, {
      include_filters: finalIncludeObjUpd,
    })
  }
  if (!isEmpty(additionalParams['selectedPg_Ids'])) {
    const { include_filters: finalIncludeObj = {} } = finalObj
    const finalIncludeObjUpd = Object.assign({}, finalIncludeObj, {
      sample_id: additionalParams['selectedPg_Ids'],
    })
    finalObj = Object.assign({}, finalObj, {
      include_filters: finalIncludeObjUpd,
    })
  }

  if (!isEmpty(additionalParams['project_id'])) {
    const { include_filters: finalIncludeObj = {} } = finalObj
    const finalIncludeObjUpd = Object.assign({}, finalIncludeObj, {
      project_id: additionalParams['project_id'],
    })
    finalObj = Object.assign({}, finalObj, {
      include_filters: finalIncludeObjUpd,
    })
  }

  if (
    isEmpty(get(finalObj, 'include_filters.status', '')) &&
    !isEmpty(additionalParams['status'])
  ) {
    const { include_filters: finalIncludeObj = {} } = finalObj
    const finalIncludeObjUpd = Object.assign({}, finalIncludeObj, {
      status: additionalParams['status'],
    })
    finalObj = Object.assign({}, finalObj, {
      include_filters: finalIncludeObjUpd,
    })
  }
  return {
    requestParams: params,
    requestPayload: finalObj,
  }
}

export const paramsFromFiltersForGET = (
  filtersObject = {},
  page = 1,
  rowsPerPage = 10,
  userProfilename = '',
  additionalParams = {},
  orderBy = '',
  order = '',
  searchQuery = ''
) => {
  const filters = !isEmpty(filtersObject) ? Object.keys(filtersObject) : {}
  let params = new URLSearchParams()
  let arrayParams = []
  params.append('key', apiConfig.apiKey)
  if (page) params.append('page', page)
  if (rowsPerPage) params.append('per_page', rowsPerPage)
  if (!isEmpty(orderBy)) params.append('sort_by', orderBy)
  if (!isEmpty(order)) params.append('sort_order', order)
  if (!isEmpty(searchQuery)) params.append('q', searchQuery)
  for (let index in filters) {
    if (
      multiSelect.includes(filters[index]) &&
      filtersObject[filters[index]].chipArray
    ) {
      const appendValue = filtersObject[filters[index]].chipArray.map(
        (a) => a.value
      )
      if (appendValue.length > 0) {
        params.append(
          DBNamesMap[filters[index]],
          filtersObject[filters[index]].chipArray.map((a) => a.value)
        )
      }
    } else if (
      projectsCollectionFilter.indexOf(filters[index]) !== -1 &&
      filtersObject[filters[index]].chipArray
    ) {
      arrayParams.push(
        filtersObject[filters[index]].chipArray.map((a) => a.value)
      )
    } else if (
      listInputs.includes(filters[index]) &&
      filtersObject[filters[index]].chipArray
    ) {
      if (filters[index] === 'Pegasus ID') {
        let idsWithoutPrefix = []
        for (let index2 in filtersObject[filters[index]].chipArray) {
          if (
            /^[a-zA-Z]{2}$/.test(
              filtersObject[filters[index]].chipArray[index2]?.substring(0, 2)
            )
          ) {
            idsWithoutPrefix.push(
              filtersObject[filters[index]].chipArray[index2]?.substring(2)
            )
          } else {
            idsWithoutPrefix.push(
              filtersObject[filters[index]].chipArray[index2]
            )
          }
        }
        params.append(DBNamesMap[filters[index]], idsWithoutPrefix)
      } else {
        params.append(
          DBNamesMap[filters[index]],
          filtersObject[filters[index]].chipArray
        )
      }
    } else if (dateInputs.includes(filters[index])) {
      if (filtersObject[filters[index]].startDate) {
        params.append(
          DBNamesMap[filters[index]] + 'start_date',
          moment(filtersObject[filters[index]].startDate).format('YYYY-MM-DD')
        )
      }
      if (filtersObject[filters[index]].endDate) {
        params.append(
          DBNamesMap[filters[index]] + 'end_date',
          moment(filtersObject[filters[index]].endDate).format('YYYY-MM-DD')
        )
      }
    } else if (
      filtersObject[filters[index]].deptClassChipArray ||
      filtersObject[filters[index]].deptChipArray
    ) {
      let combinedArray = []
      if (filtersObject[filters[index]].deptClassChipArray) {
        combinedArray.push(...filtersObject[filters[index]].deptClassChipArray)
      }
      if (filtersObject[filters[index]].deptChipArray) {
        combinedArray.push(...filtersObject[filters[index]].deptChipArray)
      }
      params.append(DBNamesMap[filters[index]], combinedArray)
    }
  }

  if (Object.keys(filtersObject).indexOf('Location-WinterStreet') !== -1) {
    const searchedLocIds = params.getAll('location_id')
    params.delete('location_id')
    const finalArr = [
      ...new Set([
        ...searchedLocIds,
        filtersObject['Location-WinterStreet']['textFieldValue'],
      ]),
    ].join(',')
    params.append('roy_location_id', finalArr)
  }

  if (!params.getAll('status').length && !isEmpty(additionalParams['status'])) {
    params.append('status', additionalParams['status'])
  }
  if (!isEmpty(additionalParams['ordered_by'])) {
    params.append('ordered_by', userProfilename)
  }
  if (!isEmpty(additionalParams['selectedPg_Ids'])) {
    params.append('sample_id', additionalParams['selectedPg_Ids'])
  }
  if (
    !isEmpty(params.getAll('project_name')) &&
    filters.indexOf('Project/Collection Name') === -1
  ) {
    params.delete('project_name')
  }
  if (!isEmpty(arrayParams)) {
    params.append('project_name', arrayParams)
  }

  return params
}

export const formatListInputValue = (filterVal = '') => {
  let filterValue = (filterVal || '').trim()
  let filterValueList = []
  if (filterValue.split(/[,\r\n\s,]+/g).length > 1 && ['']) {
    filterValue.trim()
    filterValueList = filterValue.split(/[,\r\n\s]+/g)
  } else if (filterValue.split('\n').length > 1) {
    filterValue.trim()
    filterValueList = filterValue.split('\n')
  } else if (filterValue.split(',').length > 0) {
    filterValue.trim()
    if (filterValue.charAt(filterValue.length - 1) === ',') {
      filterValue = filterValue.substring(0, filterValue.length - 1)
    }
    filterValueList = filterValue.split(',')
  } else {
    filterValueList = filterValue.trim()
  }
  return filterValueList
}

export const paramsFromAutoOrderFilters = (
  filtersObject = {},
  page = 1,
  rowsPerPage = 10,
  userProfilename = '',
  additionalParams = {},
  orderBy = '',
  order = '',
  searchQuery = ''
) => {
  const filters = !isEmpty(filtersObject) ? Object.keys(filtersObject) : {}
  let params = new URLSearchParams()
  params.append('key', apiConfig.apiKey)
  if (page) params.append('page', page)
  if (rowsPerPage) params.append('per_page', rowsPerPage)
  if (!isEmpty(orderBy)) params.append('sort_by', orderBy)
  if (!isEmpty(order)) params.append('sort_order', order)
  if (!isEmpty(searchQuery)) params.append('q', searchQuery)

  let deptClassFinal = []

  for (let index in filters) {
    if (
      multiSelect.includes(filters[index]) &&
      filtersObject[filters[index]].requestParamValue
    ) {
      if (filtersObject[filters[index]].requestParamValue.length > 0) {
        params.append(
          DBNamesMap[filters[index]],
          filtersObject[filters[index]].requestParamValue
        )
      }
    } else if (
      listInputs.includes(filters[index]) &&
      filtersObject[filters[index]].requestParamValue
    ) {
      let filterValue = filtersObject[filters[index]].requestParamValue
      let filterValueList = formatListInputValue(filterValue)
      if (filters[index] === 'Pegasus ID') {
        let idsWithoutPrefix = []
        for (let index2 in filtersObject[filters[index]].requestParamValue) {
          if (
            /^[a-zA-Z]{2}$/.test(
              filtersObject[filters[index]].requestParamValue[index2].substring(
                0,
                2
              )
            )
          ) {
            idsWithoutPrefix.push(
              filtersObject[filters[index]].requestParamValue[index2].substring(
                2
              )
            )
          } else {
            idsWithoutPrefix.push(
              filtersObject[filters[index]].requestParamValue[index2]
            )
          }
        }
        params.append(DBNamesMap[filters[index]], idsWithoutPrefix)
      } else {
        params.append(DBNamesMap[filters[index]], filterValueList)
      }
    } else if (dateInputs.includes(filters[index])) {
      const filterStartDate =
        filtersObject[filters[index]].startDate ||
        filtersObject[filters[index]]?.requestParamValue[0]
      const filterEndDate =
        filtersObject[filters[index]].endDate ||
        filtersObject[filters[index]]?.requestParamValue[1]
      if (filterStartDate) {
        params.append(
          DBNamesMap[filters[index]] + 'start_date',
          moment(filterStartDate).format('YYYY-MM-DD')
        )
      }
      if (filterEndDate) {
        params.append(
          DBNamesMap[filters[index]] + 'end_date',
          moment(filterEndDate).format('YYYY-MM-DD')
        )
      }
    } else if (
      filters[index] === 'Department' &&
      (filtersObject[filters[index]].deptClassChipArray ||
        filtersObject[filters[index]].deptChipArray ||
        filtersObject[filters[index]].requestParamValue)
    ) {
      const finalParam = filtersObject[filters[index]].requestParamValue
      params.append('dept_class', finalParam)
      deptClassFinal = [...deptClassFinal, ...finalParam]
    } else if (
      filters[index] === 'Class' &&
      (filtersObject[filters[index]].deptClassChipArray ||
        filtersObject[filters[index]].deptChipArray ||
        filtersObject[filters[index]].requestParamValue)
    ) {
      params.delete('dept_class')
      params.delete('dept_class_final')
      const deptStateFilters = filtersObject['Department']
      const deptRequestParams = get(deptStateFilters, 'value', [])
      const diffValue = deptRequestParams.filter(
        (n) =>
          !filtersObject[filters[index]].value.some(
            (n2) => n.label === n2.group_by_id
          )
      )
      const finalParam = [
        ...(diffValue.map((obj) => obj.label) || []),
        ...(filtersObject[filters[index]].requestParamValue || []),
      ]
      params.append('dept_class', finalParam)
      deptClassFinal = [...deptClassFinal, ...finalParam]
    }
  }

  if (Object.keys(filtersObject).indexOf('Location-WinterStreet') !== -1) {
    const searchedLocIds = params.getAll('location_id')
    params.delete('location_id')
    const finalArr = [
      ...new Set([
        ...searchedLocIds,
        filtersObject['Location-WinterStreet']['textFieldValue'],
      ]),
    ].join(',')
    params.append('roy_location_id', finalArr)
  }

  if (params.getAll('dept_class').length > 0 && deptClassFinal.length > 0) {
    const newParams = []
    const deptClassFinalCopy = [...new Set(deptClassFinal || [])]
    deptClassFinalCopy.map((obj) => {
      const curObjInstances = deptClassFinalCopy.filter((e) =>
        e.includes(obj)
      ).length
      if (curObjInstances === Number(1)) {
        newParams.push(obj)
      }
      return curObjInstances
    })
    if (newParams.length > 0) {
      params.delete('dept_class')
      params.append('dept_class', newParams)
    }
  }

  if (!params.getAll('status').length && !isEmpty(additionalParams['status'])) {
    params.append('status', additionalParams['status'])
  }
  if (!isEmpty(additionalParams['ordered_by'])) {
    params.append('ordered_by', userProfilename)
  }
  return params
}

export const buildRequestParams = (
  page = 1,
  rowsPerPage = 10,
  orderBy = '',
  order = '',
  searchQuery = ''
) => {
  let params = new URLSearchParams()
  params.append('key', apiConfig.apiKey)
  if (page) params.append('page', page)
  if (rowsPerPage) params.append('per_page', rowsPerPage)
  if (!isEmpty(orderBy)) params.append('sort_by', orderBy)
  if (!isEmpty(order)) params.append('sort_order', order)
  if (!isEmpty(searchQuery)) params.append('q', searchQuery)
  return params
}

export const paramsFromAutoOrderFiltersPost = (
  filtersObject = {},
  page = 1,
  rowsPerPage = 10,
  userProfilename = '',
  additionalParams = {},
  orderBy = '',
  order = '',
  searchQuery = '',
  pageType = ''
) => {
  const filters = !isEmpty(filtersObject) ? Object.keys(filtersObject) : {}
  let finalObj = {
    include_filters: {},
    exclude_filters: {},
  }
  let inCludeFiltersObj = {}
  let deptClassFinal = []

  for (let index in filters) {
    const currentFilter = filters[index] || ''
    const { [currentFilter]: currentFilterObject = {} } = filtersObject
    const {
      requestParamValue: curObjRequestParamValue,
      curObjRequestParamStartDate = null,
      curObjRequestParamEndDate = null,
    } = currentFilterObject
    if (multiSelect.includes(currentFilter) && curObjRequestParamValue) {
      if (curObjRequestParamValue.length > 0) {
        inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
          [DBNamesMap[currentFilter]]: curObjRequestParamValue,
        })
      }
    } else if (listInputs.includes(currentFilter) && curObjRequestParamValue) {
      let filterValue = curObjRequestParamValue
      let filterValueList = formatListInputValue(filterValue)
      if (currentFilter === 'Pegasus ID') {
        if (pageType === 'Project') {
          let idsWithoutPrefix = []
          filterValueList.forEach((currParamVal) => {
            if (/^[a-zA-Z]{2}$/.test(currParamVal.substring(0, 2))) {
              idsWithoutPrefix.push(currParamVal.substring(2))
            } else {
              idsWithoutPrefix.push(currParamVal)
            }
          })
          inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
            [DBNamesMap[currentFilter]]: idsWithoutPrefix,
          })
        } else {
          let idsWithoutPrefix = []
          for (let currParam in curObjRequestParamValue) {
            const currParamVal = get(curObjRequestParamValue, currParam, '')
            if (/^[a-zA-Z]{2}$/.test(currParamVal.substring(0, 2))) {
              idsWithoutPrefix.push(currParamVal.substring(2))
            } else {
              idsWithoutPrefix.push(currParamVal)
            }
          }
          inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
            [DBNamesMap[currentFilter]]: idsWithoutPrefix,
          })
        }
      } else {
        inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
          [DBNamesMap[currentFilter]]: filterValueList,
        })
      }
    } else if (dateInputs.includes(currentFilter)) {
      const filterStartDate =
        curObjRequestParamStartDate || get(curObjRequestParamValue, 0, null)
      const filterEndDate =
        curObjRequestParamEndDate || get(curObjRequestParamValue, 1, null)
      if (filterStartDate) {
        inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
          [DBNamesMap[currentFilter] + 'start_date']:
            moment(filterStartDate).format('YYYY-MM-DD'),
        })
      }
      if (filterEndDate) {
        inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
          [DBNamesMap[currentFilter] + 'end_date']:
            moment(filterEndDate).format('YYYY-MM-DD'),
        })
      }
    } else if (
      ['Category', 'Sub Category'].indexOf(currentFilter) !== -1 &&
      curObjRequestParamValue
    ) {
      const finalParam = curObjRequestParamValue
      inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
        [DBNamesMap[currentFilter]]: finalParam,
      })
      deptClassFinal = [...deptClassFinal, ...finalParam]
    } else if (
      currentFilter === 'Department' &&
      (currentFilterObject.deptClassChipArray ||
        currentFilterObject.deptChipArray ||
        curObjRequestParamValue)
    ) {
      const finalParam = curObjRequestParamValue
      inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
        dept_class: finalParam,
      })
      deptClassFinal = [...deptClassFinal, ...finalParam]
    } else if (
      currentFilter === 'Class' &&
      (currentFilterObject.deptClassChipArray ||
        currentFilterObject.deptChipArray ||
        curObjRequestParamValue)
    ) {
      delete inCludeFiltersObj['dept_class']
      delete inCludeFiltersObj['dept_class_final']
      const deptStateFilters = filtersObject['Department']
      const deptRequestParams = get(deptStateFilters, 'value', [])
      const diffValue = deptRequestParams.filter(
        (n) =>
          !currentFilterObject.value.some((n2) => n.label === n2.group_by_id)
      )
      const finalParam = [
        ...(diffValue.map((obj) => obj.label) || []),
        ...(curObjRequestParamValue || []),
      ]
      inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
        dept_class: finalParam,
      })
      deptClassFinal = [...deptClassFinal, ...finalParam]
    }
  }

  if (Object.keys(filtersObject).indexOf('Location-WinterStreet') !== -1) {
    const searchedLocIds = inCludeFiltersObj['location_id']
    delete inCludeFiltersObj['location_id']
    const finalArr = [
      ...new Set([
        ...searchedLocIds,
        filtersObject['Location-WinterStreet']['textFieldValue'],
      ]),
    ].join(',')
    inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
      roy_location_id: finalArr,
    })
  }

  if (
    get(inCludeFiltersObj, 'dept_class', []).length > 0 &&
    deptClassFinal.length > 0
  ) {
    const newParams = []
    const deptClassFinalCopy = [...new Set(deptClassFinal || [])]
    deptClassFinalCopy.map((obj) => {
      const curObjInstances = deptClassFinalCopy.filter(
        (e) => e && e.includes(obj)
      ).length
      if (curObjInstances === Number(1)) {
        newParams.push(obj)
      }
      return curObjInstances
    })
    if (newParams.length > 0) {
      delete inCludeFiltersObj['dept_class']
      inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
        dept_class: newParams,
      })
    }
  }

  if (!isEmpty(additionalParams['status'])) {
    inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
      status: additionalParams['status'],
    })
  }
  if (!isEmpty(additionalParams['ordered_by'])) {
    inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
      ordered_by: userProfilename,
    })
  }
  if (!isEmpty(additionalParams['project_id'])) {
    inCludeFiltersObj = Object.assign({}, inCludeFiltersObj, {
      project_id: additionalParams['project_id'],
    })
  }
  finalObj = Object.assign({}, finalObj, {
    include_filters: inCludeFiltersObj,
  })
  const returnParams = {
    requestParams: buildRequestParams(
      page,
      rowsPerPage,
      orderBy,
      order,
      searchQuery
    ),
    requestPayload: finalObj,
  }
  return returnParams
}

export const VENDOR_PAGE_SORT_FILTERS = [
  'tcin',
  'dpci',
  'pegasusId',
  'createDate',
  'dueDate',
  'destination',
  'manufacturerStyle',
]

export const renameKeys = (paramsObj = {}) => {
  const sample_id = paramsObj?.requestPayload?.include_filters?.sample_id
  const tcinList = paramsObj?.requestPayload?.include_filters?.tcin
  const dpciList = paramsObj?.requestPayload?.include_filters?.dpci
  if (sample_id?.length) {
    delete paramsObj.requestPayload?.include_filters?.sample_id
    paramsObj['requestPayload']['include_filters']['sample_ids'] = sample_id
  }
  if (tcinList?.length) {
    delete paramsObj.requestPayload?.include_filters?.tcin
    paramsObj['requestPayload']['include_filters']['tcins'] = tcinList
  }
  if (dpciList?.length) {
    delete paramsObj.requestPayload?.include_filters?.dpci
    paramsObj['requestPayload']['include_filters']['dpcis'] = dpciList
  }
  return paramsObj
}
export const getFilterRequestParams = (params = {}) => {
  let finalObj = {
    filter_name: '',
    filter_values: [],
  }
  const { data: paramsData = {} } = params
  let finalArray = []
  if (Object.keys(paramsData).length > 1) {
    Object.keys(paramsData).map((paramsDataInstance, index) => {
      finalObj = {
        filter_name: '',
        filter_values: [],
      }
      finalObj['filter_name'] = paramsDataInstance
      finalObj['filter_values'] = isArray(Object.values(paramsData)[index])
        ? Object.values(paramsData)[index]
        : [Object.values(paramsData)[index]]
      finalArray.push(finalObj)
      return {}
    })
  } else {
    finalObj['filter_name'] = Object.keys(paramsData)[0]
    finalObj['filter_values'] = isArray(Object.values(paramsData)[0])
      ? Object.values(paramsData)[0]
      : [Object.values(paramsData)[0]]
    finalArray.push(finalObj)
  }
  return finalArray
}
const getObject = (parms = {}, paramName = '') => {
  const newParamName = paramName.replace('start', 'end')
  return {
    startDate: (parms[paramName] && parms[paramName][0]) || null,
    endDate: (parms[newParamName] && parms[newParamName][0]) || null,
  }
}

const formatFilterCommon = (params = {}) => {
  let includeFilterObj = {}
  let filter_name = Object.keys(params)
  filter_name.map((filter_nameInstance, index) => {
    let dateInput = dateInputs.includes(DBToFilterNamesMap[filter_nameInstance])
    let listInput = listInputs.includes(DBToFilterNamesMap[filter_nameInstance])
    let booleanInput = booleanInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let locationLevelInput = locationLevelsInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let deptClassInput = deptClassInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let projectCollectionInput = projectsCollectionFilterNames.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    includeFilterObj[DBToFilterNamesMap[filter_nameInstance]] = dateInput
      ? getObject(params, filter_nameInstance)
      : listInput
        ? {
            chipArray: Object.values(params)[index],
            unrecognizedValues: [],
            islatest: true,
            textFieldValue: Object.values(params)[index].toString(),
          }
        : deptClassInput
          ? filter_nameInstance === 'dept_class'
            ? {
                deptClassChipArray: Object.values(params)[index],
              }
            : {
                [filter_nameInstance]: Object.values(params)[index],
              }
          : locationLevelInput
            ? {
                textFieldValue: Object.values(params)
                  [index].map((valuesInstance) => {
                    return valuesInstance
                  })
                  .toString(),
              }
            : projectCollectionInput
              ? {
                  chipArray: Object.values(params)[index].map(
                    (valuesInstance) => {
                      return {
                        value: [valuesInstance],
                        label: valuesInstance,
                      }
                    }
                  ),
                }
              : booleanInput
                ? Object.values(params)[index][0]
                : {
                    chipArray: Object.values(params)[index].map(
                      (valuesInstance) => {
                        return {
                          value: valuesInstance,
                          label: valuesInstance,
                        }
                      }
                    ),
                  }
    return {}
  })
  return includeFilterObj
}
export const filtersFromParamsSearch = (params = {}) => {
  const finalFiltersObj = {}
  const isNewFormat = [('exclude_filters', 'include_filters')].some((val) =>
    Object?.keys(params)?.includes(val)
  )
  const filters = isNewFormat
    ? Object.assign({}, params)
    : Object.assign({}, { include_filters: params, exclude_filters: {} })
  Object?.keys(filters)?.map((fObj) => {
    finalFiltersObj[fObj] = formatFilterCommon(filters[fObj])
    return true
  })
  return finalFiltersObj
}
export const filtersFromParams = (params = {}) => {
  let finalObj = {
    include_filters: {},
  }
  let includeFilterObj = {}
  let filter_name = Object.keys(params?.include_filters || params)
  filter_name.map((filter_nameInstance, index) => {
    let dateInput = dateInputs.includes(DBToFilterNamesMap[filter_nameInstance])
    let listInput = listInputs.includes(DBToFilterNamesMap[filter_nameInstance])
    let booleanInput = booleanInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let locationLevelInput = locationLevelsInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let deptClassInput = deptClassInputs.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    let projectCollectionInput = projectsCollectionFilterNames.includes(
      DBToFilterNamesMap[filter_nameInstance]
    )
    includeFilterObj[DBToFilterNamesMap[filter_nameInstance]] = dateInput
      ? getObject(params, filter_nameInstance)
      : listInput
        ? {
            chipArray: Object.values(params?.include_filters || params)[index],
            unrecognizedValues: [],
            islatest: true,
            textFieldValue: Object.values(params?.include_filters || params)[
              index
            ].toString(),
          }
        : deptClassInput
          ? filter_nameInstance === 'dept_class'
            ? {
                deptClassChipArray: Object.values(
                  params?.include_filters || params
                )[index],
              }
            : {
                [filter_nameInstance]: Object.values(
                  params?.include_filters || params
                )[index],
              }
          : locationLevelInput
            ? {
                textFieldValue: Object.values(params?.include_filters || params)
                  [index].map((valuesInstance) => {
                    return valuesInstance
                  })
                  .toString(),
              }
            : projectCollectionInput
              ? {
                  chipArray: Object.values(params?.include_filters || params)[
                    index
                  ].map((valuesInstance) => {
                    return {
                      value: [valuesInstance],
                      label: valuesInstance,
                    }
                  }),
                }
              : booleanInput
                ? Object.values(params?.include_filters || params)[index][0]
                : {
                    chipArray: Object.values(params?.include_filters || params)[
                      index
                    ].map((valuesInstance) => {
                      return {
                        value: valuesInstance,
                        label: valuesInstance,
                      }
                    }),
                  }

    finalObj['include_filters'] = includeFilterObj
    finalObj['exclude_filters'] = {}
    finalObj['savedData'] = true
    return {}
  })
  return finalObj
}
