import React, { useState, useEffect } from 'react'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import MomentUtils from '@date-io/moment'
import { withAuth } from '@praxis/component-auth'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Typography, TableBody, TableCell, TableRow } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import { IconButton } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, TextField } from '@material-ui/core'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Logo from '../../images/cascade-icon.svg'
import SaveIcon from '@mui/icons-material/Save'
import { useEnv } from '@praxis/component-runtime-env'
import './PopOver.css'
import { CustomPaper } from '../../util/CommonUtils'
// import Tooltip from '@material-ui/core/Tooltip'
import {
  updateSelectedColumnInfo,
  setSampleData,
  getBPIDS,
} from '../../store/order/actionCreator'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import moment from 'moment'
import { isEmpty, isArray, isUndefined, first, filter } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DATE_FORMAT } from '../../util/CommonUtils'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from '@mui/material/utils';
import apiConfig from 'apiConfig'

const styles = (theme) => ({
  vendorEmailText: {
    width: '150px',
  },
  vendorEmailTextMul: {
    overflow: 'visible !important',
  },
  optionViews: {
    paddingTop: '10px',
  },
  emailInput: {
    width: '100% !important',
  },
  vendorEmailAutoComplete: {
    width: '300px',
    paddingTop: '5%',
    display: 'flex',
    alignItems: 'flex-start',
    overflowY: 'auto',
    border: '1px solid #3874CB',
    minHeight: '300px',
    paddingBottom: '5%',
    height: 'auto',
  },
  vendorEmailACText: {
    maxHeight: '101px',
    height: 'auto',
    width: '333px',
    paddingLeft: '10px',
  },
  projectText: {
    maxHeight: '101px',
    height: 'auto',
    width: '333px',
  },
  errorColorClass: {
    color: '#B85300 !important',
  },
  appBar: {
    backgroundColor: 'white',
    position: 'relative',
    height: '35px',
    textAlign: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  popoverText: {
    textAlign: 'left',
    color: 'black',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  topMenu: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 56,
  },
  TableBody: {
    paddingTop: '0px',
    width: '900px',
    marginBottom: '10px',
  },
  errorColorClassNew: {
    borderColor: '#B85300 !important',
    '&:.MuiOutlinedInput-notchedOutline': {
      borderColor: '#B85300 !important',
    },
  },
})

const verifyQuantity = (quantity) => {
  const checkedQuantity = quantity.match('[1-9][0-9]{0,}')
    ? quantity.match('[1-9][0-9]{0,}')[0]
    : 1
  return Number(checkedQuantity)
}

const AutoOrderPopoverComponent = (props) => {
  const {
    classes,
    mouseLocationX,
    mouseLocationY,
    iconClick,
    handleClose,
    keyName = '',
    getLocationNames = () => {},
    locations = [],
    isBPartnersLoading = false,
    isBPIdsLoading = false,
    stateProjects = [],
    businessPartners = new Map(),
    businessPartnerIds =  new Map(),
    tabValue = '',
    dataObj = [],
    orderSamplesFrom,
    sampleData = [],
    selectedData = [],
  } = props
  const dispatch = useDispatch()
  const columnDataSelected = useSelector(
    (state) => state.order.columnDataSelected
  )
  const [selectedRowData, setSelectedRowData] = useState({})
  const [localSampleData, setLocalSampleData] = useState([])
  const [localEmail, setLocalEmail] = useState([])
  const [localBPIds, setLocalBPIds] = useState([])
  const [inputValue, setInputValue] = React.useState('');
  const [bpIdOptions, setBpIdOptions] = React.useState(null);
  // const [value, setValue] = React.useState(null);
  let env = useEnv()
  useEffect(() => {
    const updatedColumnDataSelected = {...dataObj}
    !isArray(updatedColumnDataSelected.vendorEmail) &&
      delete updatedColumnDataSelected.vendorEmail
    updatedColumnDataSelected.project &&
      typeof updatedColumnDataSelected.project !== 'string' &&
      delete updatedColumnDataSelected.project
    updatedColumnDataSelected.dueDate &&
      typeof updatedColumnDataSelected.dueDate !== 'string' &&
      delete updatedColumnDataSelected.dueDate
    setSelectedRowData(updatedColumnDataSelected)
    dispatch(updateSelectedColumnInfo(updatedColumnDataSelected))
    setLocalEmail(updatedColumnDataSelected?.vendorEmail || [])
    setLocalBPIds(updatedColumnDataSelected?.vendorBPIds || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const { innerWidth, innerHeight } = window
  const handleChange = (field='', value='') => {
    let data = selectedRowData
    data[field] = value
    if(field === 'vendorBPIds'){
      const vendorBPsFinalDisp = isArray(value) ? (value || []).map(bpData => `${bpData?.bp_name + '(' + bpData?.bp_id + ')'}`).join(',\n') : ''
      data['vendorBPIdsDisplay'] = vendorBPsFinalDisp
    }
    const  { locationProjectValues=[] } = env
    let projectLocal = {}
    if (field === 'shipTo' && isEmpty(projectIdFromPathname)) {
      locationProjectValues.map((locationProjectValueInstance) => {
        const { location = '', project = '' } = locationProjectValueInstance
        if (value === location) {
          projectLocal = autoOrderProjects.find((element) => {
            return element?.name === project;
          })
        }
      })
      data['project'] = projectLocal
    }
    !isArray(data?.vendorEmail) && delete data?.vendorEmail
    if (['vendorEmail'].indexOf(field) !== -1) setLocalEmail([...value])
    if (['vendorBPIds'].indexOf(field) !== -1) setLocalBPIds([...value])
    data?.project && isUndefined(data?.project) && delete data?.project
    setSelectedRowData(data)
    dispatch(updateSelectedColumnInfo(data))
  }

  const renderSuggestion = (suggestion) => <div> {suggestion.name} </div>
  const onProjectChange = (
    changeType = '',
    newValue = '',
    currentTcin = ''
  ) => {
    handleChange(changeType, newValue, currentTcin)
  }
  const projectIdFromPathname = first(
    orderSamplesFrom
      .match(/\/(\d+)+[\\/]?/g)
      ?.map((id) => id.replace(/\//g, ''))
  )
  const NOTES_LENGTH = 250
  const autoOrderProjects =
    stateProjects.filter((item) => item.autoOrderEnabled === true) || []
  const projectsToRender =
    tabValue === 'READYTOORDER' ? autoOrderProjects : stateProjects
  // This is a work around for how the cascade functionality is implemented
  const {
    vendorId: dObjVendorId = '',
    tcin: dObjTcin = '',
    project: dObjProject = '',
    studioNotes: dObjStudioNotes = '',
    vendorNotes: dObjVendorNotes = '',
  } = selectedRowData
  const { name: dObjProjectName = '' } = dObjProject || {}
  const doesProjectExists =
    dObjProject === '' ||
    isEmpty(dObjProject) ||
    !projectsToRender?.some(
      (obj) => obj.name === dObjProjectName || dObjProject
    )
  const vendorPartners = businessPartners.get(dObjVendorId) || []
  const vendorBPIds = businessPartnerIds.get(dObjVendorId) || []
  // eslint-disable-next-line no-useless-escape
  const validEmailRegex =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const getValidEmails = (
    emailList = [],
    currentValue = '',
    vendorEmail = []
  ) => {
    const fillteredArr = emailList.filter((i) => validEmailRegex.test(i))
    let returnObj = emailList
    if (!isEmpty(currentValue)) {
      const filledArr = fillteredArr.filter(
        (filterArrayInstance) =>
          !(vendorEmail || []).some(
            (vendorEmailInstance) =>
              vendorEmailInstance.toLowerCase() ===
              filterArrayInstance.toLowerCase()
          )
      )
      returnObj = [...vendorEmail, ...filledArr]
    }

    return returnObj
  }

  const getValidBpIds = (
    bpIdList = [],
    currentValue = '',
    bpIds = []
  ) => {
    const fillteredArr = bpIdList.filter((i) => /^[0-9]{1,8}$/.test(i))
    let returnObj = bpIdList
    if (!isEmpty(currentValue)) {
      const filledArr = fillteredArr.filter(
        (filterArrayInstance) =>
          !(bpIds || []).some(
            (bpIdInstance) =>
              bpIdInstance ===
              filterArrayInstance
          )
      )
      returnObj = [...bpIds, ...filledArr]
    }

    return returnObj
  }
  const onPasteVendorEmail = (e, selectedRowData) => {
    e.preventDefault()
    const pastedStr = e.clipboardData.getData('text/plain') || ''
    const emailInAStringRegex =
      /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    let newChips = pastedStr.match(emailInAStringRegex)
    const result = getValidEmails(newChips, pastedStr, localEmail)
    handleChange('vendorEmail', result, selectedRowData.tcin)
  }

  const onPasteVendorBPIds = (e, selectedRowData) => {
    e.preventDefault()
    const pastedStr = e.clipboardData.getData('text/plain') || ''
    const bpIdRegex =
      /\d+/g
    const newChips = pastedStr.match(bpIdRegex)
    const validNewChips = newChips.filter(obj => /^[0-9]{1,8}$/.test(obj)) 
    const result = Array.from(new Set([...localBPIds, ...validNewChips]))
    handleChange('vendorBPIds', result, selectedRowData.tcin)
  }

  const fetchvendors = React.useMemo(
    () =>
      debounce((request, callback) => {
        const searchText = request?.input || ''
        dispatch(getBPIDS(searchText, callback))
      }, 400),
      [],
    );

  useEffect(() => {
    fetchvendors({ input: inputValue }, (results) => {
      const bpIdsList = results?.data ?? null
      setBpIdOptions(bpIdsList)
    });
  }, [inputValue, fetch]);


  const renderPopOverFields = () => {
    switch (keyName) {
      case 'dueDate':
        return (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container>
              <KeyboardDatePicker
                variant="inline"
                id="dueDate"
                autoOk
                inputVariant="outlined"
                clearable={'true'}
                disablePast
                onChange={(date) => {
                  handleChange('dueDate', moment(date).format(DATE_FORMAT))
                }}
                error={
                  selectedRowData.dueDate === null ||
                  isUndefined(selectedRowData.dueDate) ||
                  // moment(moment(selectedColumnDatadueDate), 'MM/DD/YYYY', false) <
                  //   moment().toDate() ||
                  !moment(
                    moment(selectedRowData.dueDate),
                    'MM/DD/YYYY',
                    false
                  ).isValid()
                }
                value={moment(columnDataSelected['dueDate']) || null}
                format={DATE_FORMAT}
                placeholder={'__/__/____'}
                style={{ width: '100%' }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )
      case 'quantity':
        return (
          <TextField
            className={classes.textField}
            id="standard-number"
            name="quantity"
            label="Quantity"
            variant="outlined"
            InputProps={{ inputProps: { min: 1 } }}
            onChange={(value) =>
              handleChange(
                value.target.name,
                verifyQuantity(value.target.value),
                columnDataSelected.tcin
              )
            }
            error={columnDataSelected.quantity <= 0}
            value={columnDataSelected.quantity}
            style={{ width: '300px' }}
            type="number"
          />
        )
      case 'shipTo':
        return (
          <TextField
            id="shippingLocation"
            error={
              !!isEmpty(columnDataSelected.shipTo) ||
              columnDataSelected.shipTo === 'none'
            }
            label="Select Location"
            name="shipTo"
            variant="outlined"
            onChange={(value) =>
              handleChange(
                value.target.name,
                value.target.value,
                columnDataSelected.tcin
              )
            }
            select
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
              native: true,
            }}
            style={{ width: 'auto' }}
            value={columnDataSelected?.shipTo || 'none'}
          >
            <option value="none"> {columnDataSelected?.shipTo} </option>
            {locations !== undefined
              ? getLocationNames(locations).map((option) => (
                  <option value={option.name} key={option.name}>
                    {option.name}
                  </option>
                ))
              : ''}
          </TextField>
        )
      case 'vendorEmail':
        return isBPartnersLoading ? (
          <CircularProgress />
        ) : (
          <Autocomplete
            multiple
            id="vendorEmail"
            name="vendorEmail"
            autoComplete
            className={classes.vendorEmailAutoComplete}
            value={
              localEmail ||
              columnDataSelected.vendorEmail ||
              vendorPartners ||
              []
            }
            classes={{
              input: classes.emailInput,
            }}
            ChipProps={{
              color: 'grey',
              size: 'small',
            }}
            freeSolo
            disableCloseOnSelect
            options={vendorPartners || []}
            onChange={(event, newValue) => {
              const currentValue = event.target.value || ''
              const result = getValidEmails(
                newValue,
                currentValue,
                columnDataSelected.vendorEmail
              )
              handleChange('vendorEmail', result, dataObj.tcin)
            }}
            defaultValue={vendorPartners}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Emails"
                name="vendorEmail"
                //InputProps={{ disableUnderline: true }}
                onPaste={(e) => onPasteVendorEmail(e, dataObj)}
                className={classes.vendorEmailACText}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
              />
            )}
          />
        )
        case 'vendorBPIdsDisplay':
          return isBPartnersLoading ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              loading={isBPIdsLoading}
              loadingText="Loading..."
              multiple
              id="vendorBPIds"
              name="vendorBPIds"
              PaperComponent={CustomPaper}
              autoComplete
              className={classes.vendorEmailAutoComplete}
              getOptionLabel={(option = {}) => {
                const { bp_id = '', bp_name = '' } = option
                const optionName = `${bp_name + '(' + bp_id + ')'}`
                return typeof option === 'object' ? optionName : option
              }}
              value={
                localBPIds ||
                columnDataSelected.vendorBPIds ||
                vendorBPIds ||
                []
              }
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              classes={{
                input: classes.emailInput,
              }}
              ChipProps={{
                color: 'grey',
                size: 'small',
              }}
              freeSolo
              disableCloseOnSelect
              options={bpIdOptions?.length ? bpIdOptions : vendorBPIds || null}
              onChange={(event, newValue) => {
                const currentValue = event.target.value || ''
                const result = getValidBpIds(
                  newValue,
                  currentValue,
                  columnDataSelected.vendorBPIds
                )
                handleChange('vendorBPIds', result, dataObj.tcin)
              }}
              defaultValue={vendorBPIds}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Business Partners"
                  name="vendorBPIds"
                  onPaste={(e) => onPasteVendorBPIds(e, dataObj)}
                  className={classes.vendorEmailACText}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
              )}
            />
          )
      case 'studioNotes':
        return (
          <TextField
            className={classes.optionViews}
            id="studioNotes"
            multiline
            rows={9}
            variant="outlined"
            label="Studio Notes"
            onChange={(value) =>
              handleChange(
                'studioNotes',
                value.target.value,
                columnDataSelected.tcin
              )
            }
            value={columnDataSelected?.studioNotes || ''}
            placeholder="Enter notes..."
            style={{ minWidth: '200px', width: '100%' }}
            defaultValue={isEmpty(dObjStudioNotes) ? ' ' : dObjStudioNotes}
            inputProps={{
              maxLength: NOTES_LENGTH,
            }}
            InputProps={{
              classes: {
                inputMultiline: classes.vendorEmailTextMul,
              },
            }}
            helperText={
              NOTES_LENGTH - dObjStudioNotes.length + ' characters remaining'
            }
          />
        )
      case 'vendorNotes':
        return (
          <TextField
            className={classes.optionViews}
            id="vendorNotes"
            label="Vendor Notes"
            multiline
            rows={9}
            variant="outlined"
            onChange={(value) =>
              handleChange(
                'vendorNotes',
                value.target.value,
                columnDataSelected.tcin
              )
            }
            value={columnDataSelected?.vendorNotes || ''}
            placeholder="Enter notes..."
            style={{ minWidth: '200px', width: '100%' }}
            defaultValue={isEmpty(dObjVendorNotes) ? ' ' : dObjVendorNotes}
            inputProps={{
              maxLength: NOTES_LENGTH,
            }}
            InputProps={{
              classes: {
                inputMultiline: classes.vendorEmailTextMul,
              },
            }}
            helperText={
              NOTES_LENGTH - dObjVendorNotes.length + ' characters remaining'
            }
          />
        )
      case 'project':
        return (
          <Autocomplete
            id="project-dropdown"
            style={{ width: 'auto', border: '1px' }}
            getOptionLabel={(option = {}) => {
              const { name: optionName = '' } = option
              return typeof option === 'object' ? optionName : option
            }}
            options={projectsToRender}
            // label="Project"
            autoComplete
            variant="outlined"
            // className={classes1.root}
            includeInputInList
            filterSelectedOptions
            value={
              !isEmpty(projectIdFromPathname) && !isEmpty(dObjProject)
                ? dObjProject?.name || dObjProject
                : columnDataSelected['project']
            }
            onChange={(event, newValue) => {
              onProjectChange('project', newValue, dObjTcin)
            }}
            PaperComponent={CustomPaper}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                label="projects"
                id="projects"
                variant="outlined"
                placeholder="projects"
                error={doesProjectExists}
                className={classes.projectText}
              />
            )}
            renderOption={renderSuggestion}
          />
        )
      default:
        return ''
    }
  }
  const updateSelectedRows = () => {
    let localSelectedData = selectedData
    let localSampleData = sampleData.map((a) => {
      return { ...a }
    })
    localSampleData.map((localSampleDataInstance) => {
      localSelectedData.map((localSelectedDataInstance) => {
        if (localSampleDataInstance.tcin === localSelectedDataInstance.tcin) {
          localSampleDataInstance[keyName] = columnDataSelected[keyName] || ''
        }
        return {}
      })
      return {}
    })
    setLocalSampleData(localSampleData)
    let newData = !isEmpty(localSampleData) ? localSampleData : sampleData
    const index = newData.findIndex((el) => el.tcin === columnDataSelected.tcin)
    newData[index] = selectedRowData
    dispatch(setSampleData(newData))
    dispatch(updateSelectedColumnInfo(selectedRowData))
    handleClose()
  }
  const updateAllRows = () => {
    let localSampleData = sampleData.map((a) => {
      return { ...a }
    })
    localSampleData.map((localSampleDataInstance) => {
      let projectLocal = {}
      localSampleDataInstance[keyName] = columnDataSelected[keyName] || ''
      if(keyName === 'vendorBPIdsDisplay') {
        localSampleDataInstance['vendorBPIds'] = columnDataSelected['vendorBPIds'] || ''
      }
      localSampleDataInstance['project'] = !isEmpty(projectIdFromPathname) && !isEmpty(dObjProject) ? dObjProject?.name || dObjProject : columnDataSelected['project'] || ''
      if (keyName === 'shipTo' && isEmpty(projectIdFromPathname)) {
        const  { locationProjectValues=[] } = env
        locationProjectValues.map((locationProjectValueInstance) => {
          const { location = '', project = '' } = locationProjectValueInstance
          if (columnDataSelected[keyName] === location) {
            projectLocal = autoOrderProjects.find((element) => {
              return element?.name === project;
            })
          }
        })
        localSampleDataInstance['project'] = projectLocal
      }
   
      return {}
    })
    setLocalSampleData(localSampleData)
    let newData = !isEmpty(localSampleData) ? localSampleData : sampleData
    const index = newData.findIndex((el) => el.tcin === columnDataSelected.tcin)
    newData[index] = selectedRowData
    dispatch(setSampleData(newData))
    dispatch(updateSelectedColumnInfo(selectedRowData))
    handleClose()
  }
  const onSaveClick = () => {
    let newData = !isEmpty(localSampleData) ? localSampleData : sampleData
    const index = newData.findIndex((el) => el.tcin === columnDataSelected.tcin)
    newData[index] = selectedRowData
    dispatch(setSampleData(newData))
    dispatch(updateSelectedColumnInfo(selectedRowData))
    handleClose()
  }
  const getDiabled = () => {
    // Is empty to check for empty arrary for email, project list
    let isInvalidData = isEmpty(columnDataSelected[keyName])
    switch (keyName) {
      case 'quantity':
        isInvalidData = !columnDataSelected[keyName]
        break
      case 'vendorBPIdsDisplay':
        isInvalidData = isEmpty(columnDataSelected['vendorBPIds'])
        break
      default:
        isInvalidData = isEmpty(columnDataSelected[keyName])
        break
    }
    return isInvalidData
  }
  return (
    <Popover
      open={iconClick}
      onClose={handleClose}
      className="popover"
      id="simple-popover"
      anchorReference="anchorPosition"
      transformOrigin={{
        vertical: 'left',
        horizontal: 'left',
      }}
      anchorPosition={{
        top: mouseLocationY,
        left: mouseLocationX - 200,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          minHeight: 100,
        },
      }}
    >
      <Box style={{ width: 'auto' }}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid item style={{ alignItems: 'flex-end' }}>
              <Typography variant="h7" className={classes.popoverText}>
                TCIN - {columnDataSelected.tcin}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 30, marginLeft: 'auto' }}>
              <IconButton
                size="small"
                edge="end"
                color="inherit"
                onClick={() => handleClose()}
              >
                <CloseIcon sx={{ color: 'grey' }} />
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>
        <br></br>
        <TableBody className={classes.TableBody}>
          <TableRow>
            <TableCell style={{ borderBottom: 'none', paddingTop: '20px' }}>
              <Box>{renderPopOverFields()}</Box>
            </TableCell>
          </TableRow>

          <TableBody>
            <TableRow>
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Button
                      color="primary"
                      onClick={() => updateAllRows()}
                      startIcon={<Logo />}
                      disabled={getDiabled()}
                    >
                      {`Save to all rows (${sampleData.length})`}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Button
                      color="primary"
                      onClick={() => updateSelectedRows()}
                      disabled={!selectedData.length || getDiabled()}
                      startIcon={<ContentCopyIcon sx={{ color: '#366CD9' }} />}
                    >
                      {`Save to selected rows (${selectedData.length})`}
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      marginBottom: 20,
                      borderBottom: 'none',
                      width: '100%',
                      color: '#366CD9',
                    }}
                  >
                    <Button
                      color="primary"
                      startIcon={<SaveIcon sx={{ color: '#366CD9' }} />}
                      onClick={() => onSaveClick()}
                      disabled={getDiabled()}
                    >
                      <span
                        sx={{ color: '#366CD9' }}
                      >{`Save to this row`}</span>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableRow>
          </TableBody>
        </TableBody>
      </Box>
    </Popover>
  )
}

export default withStyles(styles)(withAuth()(AutoOrderPopoverComponent))
